import React from 'react'
import polygonNo1 from "../../Resources/polygon-01.webp"
import polygonNo2 from "../../Resources/polygon-02.webp"
import polygonNo3 from "../../Resources/polygon-03.webp"

const BecomSellerDesc = (props) => {
  return (
    <section>
        <div className='relative my-8 xl:my-12'>
            <img src={props.image === 'polygonNo1' ? polygonNo1 : (props.image === 'polygonNo2' ? polygonNo2 : polygonNo3)} alt="" className={`absolute -top-6 -left-2 w-14 xl:w-28 xl:-left-[5.3rem] ${props.heading ? 'xl:-top-10' : 'xl:-top-14'} `}/>
            
            <p className={`${props.heading ? 'mt-4 ' : ''} text-[15px] sticky !z-[999999] font-outfit font-bold text-[#000000] text-justify px-8 xl:px-0 xl:text-[20px]`}>{props.heading}</p>
            
            <p className={`${props.heading ? '-mt-1 ' : ''} text-[15px] sticky z-10 font-outfit font-medium text-[#000000] text-justify px-8 xl:px-0 xl:text-[19px] leading-5`}>{props.description}</p>
        </div>
    </section>
  )
}

export default BecomSellerDesc