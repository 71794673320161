import React, { useEffect } from 'react'

import ContentsClip from "../Resources/ContentsClip.webp"
import PackageClip from "../Resources/PackageClip.webp"
import LogoClip from "../Resources/LogoClip.webp"
import { AboutUsListData } from "../Data/ListDatas"
import Polygongolden from "../Resources/aboutUsDignalClip.webp"

import { Link } from "react-router-dom";
import AboutMainBanner from '../Components/Reuseable/AboutMainBanner';
import StepsTile from './StepsTile';
import { Teams } from './teams';

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      className="mx-auto w-full rounded-2xl"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);
function Aboutus() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const SampleStepsTileData = [
    {
      Id: Math.random(),
      Count: "01",
      Title: `Provide your business details & LOGO`,
      Image: LogoClip
    },
    {
      Id: Math.random(),
      Count: "02",
      Title: "Website contents",
      Image: ContentsClip
    },
    {
      Id: Math.random(),
      Count: "03",
      Title: "Select a package as per your convenience & PAY",
      Image: PackageClip
    },


  ]

  return <div className='w-full flex flex-col items-center bg-[#FFFFFF]'>
    <div className='w-full '>
      <AboutMainBanner
        aboutUs
        PathRoute={"ABOUT US"}
        AboutWhat="About"
        AboutWhat1="WEBBEESITE."
        Desc={['We are a reliable website development company in UAE providing solutions for all your website development problems.']}
        showbuttun={false}
      />
    </div>
    <div className=''>
      <div className='flex justify-center items-center w-[90%] xl:w-[80%] mx-auto xl:pr-8 pt-7 pb-10'>
        <p className='font-outfit text-[#000000] text-base xl:text-[15px] text-justify'>
        Nearly 4.66 billion people, which is about 59.5% of the world's population, use the Internet. In some countries, this number is even higher. For example, in the USA alone, over 90% of people are online and over 99% of the total population of UAE is active internet user. <br/><br/>
        If your business doesn't have a website, it becomes invisible to potential customers and the business community. As we move further into the digital world, people won't know about your business unless you invest in a website and establish an online presence.<br/><br/>
        Welcome to <span className='text-[#F2B307] font-bold'>WEBBEESITE</span>! A user-friendly Content Management System (CMS) designed to help users create and customize their websites without the need for a developer or coding expertise. Whether you have prior experience in web development or not, <span className='text-[#F2B307] font-bold'>WEBBEESITE</span> offers a coding-free platform that empowers its users to build, customize, and manage their websites according to their preferences.<br/><br/>
        The technology behind <span className='text-[#F2B307] font-bold'>WEBBEESITE</span> provides an easy and intuitive backend interface, allowing users to effortlessly upload content, images, and change color themes to personalize their websites all without paying the developer extra charges.
        <br/><br/>
        At <span className='text-[#F2B307] font-bold'>WEBBEESITE</span>, our primary aim is to offer a holistic business solution. Our portfolio goes beyond just providing a coding-free Content Management System (CMS). We've introduced innovative business tools such as  <span className='text-[#F2B307] font-bold'>WEBBEECART</span>, a tailored e-commerce solution,  <span className='text-[#F2B307] font-bold'>WEBBEE Client</span>, a cutting-edge invoicing and payment solution, and an efficient Customer Relationship Management (CRM) solution.<br/><br/>
        <span className='text-[#F2B307] font-bold'>WEBBEESITE</span> strives to be your one-stop-shop for all your business requirements. Imagine the ease of having everything you need in one place—no more juggling between different companies for web development, ecommerce, billing, client relationships, and other business management tasks. With <span className='text-[#F2B307] font-bold'>WEBBEESITE</span>, enjoy the peace of mind that comes with streamlined operations and comprehensive solutions, all conveniently under one roof.<br/><br/>
        <b>
          Co-Founder <br/>
          MUHAMMAD ARIF
        </b>
        </p>
      </div>
    </div>
    <div className="flex flex-col gap-y-4 mb-10 xl:my-20">
        <div className="w-[90%] mx-auto">
          <p className="text-[#2D2D2D] text-[24px] sm:text-[14px] md:text-[24px] lg:text-[32px] xl:text-5xl font-outfit font-extrabold text-center">
            What is{" "}
            <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%] uppercase">
              Webbeesite?
            </span>
          </p>
          <p className="text-[#2D2D2D] text-[18px] sm:text-[10px] md:text-[20px] lg:text-[22px] font-outfit font-semibold text-center py-2">
          WEBBEESITE is a user-friendly Content Management System (CMS) that allows users to effortlessly create and manage websites without requiring any developer skills. Sounds interesting right?
            <span className="text-[#f7bb04]"> Enjoy the Video!</span>
          </p>
        </div>
        <div className="relative w-[90%] lg:w-[60%] mx-auto">
          <YoutubeEmbed embedId="wpuie2BTC9Y?si=ml_dfA9GBIsr7ry6" />
        </div>
      </div>
    <div className='w-[90%] xl:w-[80%] mx-auto mt-6'>
      <p className='font-outfit font-extrabold text-[#000000] text-3xl xl:text-4xl text-center'>Follow the below <span className='bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%]'>three simple</span> Steps</p>
      <div className='h-1 xl:h-[2px] bg-[#F2B307] w-40 xl:w-[7%] my-4 mx-auto'> </div>
      {/* <p className='font-manrope text-base xl:text-[15px] text-center xl:text-left'>So, what are you waiting for? Register with us in <span className='text-[#F2B307]'>three simple steps:</span></p> */}
    </div>
    <div className='flex flex-row w-[80%] xl:justify-between xl:items-center mx-auto overflow-x-scroll xl:overflow-x-auto scrollbar-none mt-5 xl:mt-0'>
      {SampleStepsTileData?.map((item, index) => {
        return (
          <StepsTile
            key={index}
            Title={item.Title}
            Count={item.Count}
            Image={item.Image}
          />
        )
      })}
    </div>
    <div className='w-[90%] xl:w-[80%] mx-auto pt-1 text-center xl:text-left'>
      <p className='font-outfit font-medium text-[#000000] text-center text-[17px]'><span className='text-[#F2B307] mr-1'>WEBBEESITE</span> representative will contact you and your amazing, secure website is ready in 72 hours. It's just that simple.</p>
    </div>

    <div className=' justify-center items-center my-8 bg-main-bg-convinced bg-no-repeat bg-cover py-10'>
      <div className='w-[90%] xl:w-[80%] mx-auto'>
        <div className='flex flex-col items-center xl:items-start'>
          <div className='w-full'>
            <h2 className='font-outfit font-extrabold justify-center text-3xl xl:text-4xl text-[#000000] flex'><span className='bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%] mr-1'>Still </span> not Convinced?</h2>
          </div>
          <div className='h-[2px] bg-[#F2B307] w-24 my-4 xl:mx-auto'></div>
        </div>

        <div className='flex xl:justify-center'>
          <p className='font-outfit font-medium text-[#000000] text-[18px] text-center xl:text-xl xl:w-[50%]'>
          Having a website is crucial for businesses nowadays. If you don't have one yet, <span className=' text-[#F2B307]' >WEBBEESITE</span>, one of the top website development company in UAE, can help you create a customized website. Having a website offers several advantages:
          </p>
        </div>
        <div className='bg-black px-8 py-8 rounded-3xl mt-12'>
          {/* <div className='mt-7 w-full flex flex-col items-center'>
            <p className='font-outfit font-medium text-justify xl:block text-[20px] lg:text-xl xl:text-left text-[#FFFFFF] pb-4'>
              Websites have become essential to business today. <span className=' text-[#F2B307]' >WEBBEESITE</span> can get your  customized website and strongly recommend creating one if you haven’t done so already.
            </p>
          </div> */}
          {
            AboutUsListData.map((i, index) => {
              return (
                <div key={index} className='flex mt-[6px]'>
                  <img className='h-6 w-6 xl:h-4 xl:w-4 border-r-emerald-600 flex mt-[2px]' src={Polygongolden} alt='' />
                  <p className='font-outfit font-medium text-[#FFFFFF] text-[17px] xl:text-sm ml-4 xl:ml-2 self-center lg:text-justify' dangerouslySetInnerHTML={{ __html: i.Content }} >
                    {/* {i.Content} */}
                  </p>
                </div>
              )
            })
          }
          <div className='flex justify-center mt-8'>
            {/* <Bookdemocard
              background={"bg-[#000000] text-white"}
              background2={"bg-[#FFBB00]"}
            /> */}
            <Link to={"/createwebsite"}>
            <button className='bg-white pb-[3px] cursor-pointer rounded-lg sm:rounded-md lg:rounded-lg w-max'>
              <p className='bg-gkYellow rounded-lg sm:rounded-md lg:rounded-lg w-max py-[13px] font-outfit font-bold text-[16px] lg:text-[18px] xl:text-sm px-5 xl:px-6 text-black'>Start Building Your Website Now</p>
            </button>
            </Link>
            {/* <Bookdemocardnew background={"bg-[#F2B307] text-[#000000]"} background2={"bg-[#FFFFFF]"}/> */}
          </div>
        </div>


        {/* <div className='flex justify-center mt-5'>
          <button type='button' className='bg-[#F2B307] text-sm xl:mr-20 rounded-md px-6 py-3 flex items-center hover:bg-white hover:text-[#F2B307] text-white '>
            Book Your Demo Now
          </button>
        </div> */}

      </div>

    </div>
    <Teams />
  </div>
}

export default Aboutus;
