import React from "react";
import { WebbeeCartListData } from "../Data/ListDatas";
import Polygongolden from "../Resources/aboutUsDignalClip.webp";
import webbeecartseamless from "../Resources/webbeecartseamless.webp";
import webbeecartfeatures from "../Resources/webbeecartfeatures.webp";
import WebbeecartMainBanner from "../Components/Reuseable/WebbeecartMainBanner";
import Seperator from "../Components/Seperator";
import Bookdemocard from "../Components/Bookdemocardnew";
import appstore from "../Resources/appstoreWebbeecart.webp";
import playstore from "../Resources/playstoreWebbeecart.webp";
import appstoreIcon from "../Resources/appstore-icon.webp";
import playstoreIcon from "../Resources/googleplay-icon.webp";

function WebbeeCart() {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  return (
    <div className="w-full flex flex-col items-center bg-[#FFFFFF]">
      <div className="w-full ">
        <WebbeecartMainBanner
          aboutUs
          PathRoute={"Webbecart"}
          AboutWhat="About"
          AboutWhat1="Webbeecart"
          Desc={[
            "WEBBEECART - Our feature-rich ecommerce solutions that deliver seamless and enjoyable shopping experiences.",
          ]}
          showbuttun={false}
        />
      </div>
      <div className="flex flex-col-reverse lg:flex-row w-[90%] xl:w-[80%] mx-auto py-8">
        <div className="w-full xl:pr-6 pb-10 ">
          <div className="flex flex-col items-center lg:items-start font-outfit font-extrabold text-center lg:text-left text-[32px] lg:text-[42px] text-[#000000] leading-tight">
            <p className="">Your One-Stop Shop for</p>
            <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">
              Seamless Shopping
            </span>
            <p className="">Experiences</p>
            <Seperator />
          </div>
          <p className="font-outfit text-[#000000] text-base xl:text-[15px] pt-4">
            At <span className="text-[#F2B307] font-bold">WEBBEECART</span>, we
            are on a mission to make online selling easy for you. We provide a
            fully responsive, scalable, and custom ecommerce website with a
            mobile app within 30 days. Our all-in-one E-commerce solution is
            designed for businesses of all sizes either single vendor or
            multiple vendors.
            <br />
            <br />
            We specialize in creating responsive and user-friendly online stores
            that give digital brands to connect with customers and gain a
            competitive edge. Our online stores have appealing designs for great
            user experiences, intuitive shopping carts for fast checkout, and
            SEO and marketing automation tools for increased store visibility.
            <br />
            <br />
            We designed our Ecommerce solution with both sellers and buyers in
            mind. We made sure to include features for both, aiming to make the
            buying and selling experience easy and enjoyable for everyone.
            <br />
            <br />
            But that's not all -{" "}
            <span className="text-[#F2B307] font-bold">WEBBEECART</span> also
            offers a digital app, giving retailers an additional digital
            touchpoint to connect with their target audience and provide
            convenient shopping experiences. Our mobile app enables you to
            display more relevant products when a customer search something on
            your store. You can track your customers buying habits and provide
            fast credit card or digital wallet checkout, and use push
            notifications to improve customer engagement.
            <br />
            <br />
            And here's the best part - no need to worry about installations,
            contracts, or hidden fees. Just create an account, and you can start
            building your online store. Plus, our technical support team is
            ready to assist you in getting started. Your journey to successful
            online business starts here.
          </p>
        </div>
        <div className="w-[90%] mx-auto 2xl:h-[730px]">
          <img
            src={webbeecartseamless}
            className="w-full h-full object-contain"
            alt=""
          />
        </div>
      </div>
      <div className=" justify-center items-center bg-main-bg-convinced bg-no-repeat bg-cover py-10">
        <div className="w-[90%] xl:w-[80%] mx-auto">
          <div className="flex flex-col items-center xl:items-start">
            <div className="w-full">
              <h2 className="font-outfit font-extrabold justify-center text-3xl xl:text-4xl text-[#000000] flex">
                Features Of
                <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%] ml-2">
                  Webbeecart{" "}
                </span>
              </h2>
            </div>
            <div className="h-[2px] bg-[#F2B307] w-24 my-4 xl:mx-auto"></div>
          </div>
          <div className="bg-black px-8 py-8 rounded-3xl mt-12 flex flex-col lg:flex-row w-full">
            <div className="w-[90%] mx-auto">
              <img
                src={webbeecartfeatures}
                className="w-full h-full object-contain"
                alt=""
              />
            </div>
            <div className="w-full">
              {WebbeeCartListData.map((i, index) => {
                return (
                  <div key={index} className="flex mt-[6px]">
                    <img
                      className="h-6 w-6 xl:h-4 xl:w-4 border-r-emerald-600 flex mt-[2px]"
                      src={Polygongolden}
                      alt=""
                    />
                    <p
                      className="font-outfit font-medium text-[#FFFFFF] text-[17px] xl:text-sm ml-4 xl:ml-2 self-center lg:text-justify"
                      dangerouslySetInnerHTML={{ __html: i.Content }}
                    >
                      {/* {i.Content} */}
                    </p>
                  </div>
                );
              })}
              <div className="mt-6">
                <div className="">
                  <p className="font-outfit font-extrabold text-center lg:text-left text-[32px] text-white pt-1">
                    Scan QR Code
                  </p>
                  <div className="flex flex-col lg:flex-row justify-between items-center w-[90%] mx-auto lg:w-full mt-4 gap-8">
                    <div className="w-full flex flex-row p-2 rounded-md gap-2 md:gap-12 lg:gap-2 xl:gap-4 2xl:gap-6">
                      <div className="w-[40%]">
                        <img
                          src={appstore}
                          className="w-full h-full object-contain"
                          alt="QR for AppStore"
                        />
                      </div>
                      <div className="w-[90%] mx-auto">
                        <img
                          src={appstoreIcon}
                          className="w-full h-full object-contain"
                          alt=""
                        />
                      </div>
                      {/* <div className="flex flex-col items-start justify-center text-[20px] md:text-[34px] lg:text-[20px] font-outfit  leading-tight w-full 2xl:px-2">
                <p>Download for AppStore</p>
              </div> */}
                    </div>
                    <div className="w-full flex flex-row p-2 rounded-md gap-2 md:gap-12 lg:gap-2 xl:gap-4 2xl:gap-6">
                      <div className="w-[40%]">
                        <img
                          src={playstore}
                          className="w-full h-full object-contain"
                          alt="QR for PlayStore"
                        />
                      </div>
                      {/* <div className="flex flex-col items-start justify-center text-[20px] md:text-[34px] lg:text-[20px] font-outfit  leading-tight w-full 2xl:px-2">
                <p>Download for Playstore</p>
              </div> */}
                      <div className="w-[90%] mx-auto">
                        <img
                          src={playstoreIcon}
                          className="w-full h-full object-contain"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* {<Bookdemocard
            background={"bg-[#F2B307] text-black"}
            background2={"bg-[#FFFFFF]"}
          />} */}
                {/* <button
              type="button"
              className="bg-[#F2B307] text-sm xl:mr-20 rounded-md px-6 py-3 flex items-center hover:bg-white hover:text-[#F2B307] text-white "
            >
              Book Your Demo Now
            </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebbeeCart;
