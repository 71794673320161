export const AboutUsListData = [
    {
        Id: Math.random(),
        Content: "<b>Professionalism and Credibility:</b> A mobile-friendly web design gives your business a professional, and credible image."
    },
    {
        Id: Math.random(),
        Content: "<b>Showcasing Products and Services:</b> You can display your products and services clearly on your website with our web design agency."
    },
    {
        Id: Math.random(),
        Content: "<b>Highlighting Reviews and Testimonials:</b> Your best reviews and testimonials can be prominently featured on your custom website."
    },
    {
        Id: Math.random(),
        Content: "<b>Encouraging Customer Engagement:</b> A SEO-friendly website can encourage customers to reach out to you."
    },
    {
        Id: Math.random(),
        Content: "<b>Attracting New Customers:</b>  Through Google marketing, your website can attract new customers."
    },
    {
        Id: Math.random(),
        Content: "<b>Google Maps Integration:</b> Integrating your website with Google Maps makes it easier for people to find your location."
    },
    {
        Id: Math.random(),
        Content: "<b>Establishing Industry Presence:</b> Your website helps establish your position in the industry as we provide cheap website design in Dubai."
    },
    {
        Id: Math.random(),
        Content: "<b>Easy Website Creation and Management:</b> <span className='text-[#F2B307] font-bold'>WEBBEESITE</span> simplifies the process of building an attractive, mobile-friendly website without any coding knowledge."
    },
    {
        Id: Math.random(),
        Content: "<b>Long-Term Success:</b> A website ensures your long-term success, and you can enjoy all these benefits without spending a fortune."
    },
    {
        Id: Math.random(),
        Content: "<b>Affordable Solution:</b> <span className='text-[#F2B307] font-bold'>WEBBEESITE</span> offers affordable website development solution, and by following a simple DIY approach, you can get a website at a minimal price."
    }

]
export const WebbeeCartListData = [
    {
        Id: Math.random(),
        Content: "<b>Modern Store Design:</b> Your <b>WEBBEECART</b> ecommerce website templates are fully responsive to mobile devices and never struggles under large numbers of products like many other platforms do."
    },
    {
        Id: Math.random(),
        Content: "<b>Hawkeye Dashboard:</b> Our panoramic dashboard gives an easy and quick overview and tracking of the store including total users, total products, total orders, pending orders, items sold, returns, net sales, top categories, top products, and much more."
    },
    {
        Id: Math.random(),
        Content: "<b>User Administration:</b> Experience ease in managing your store by adding new users and assigning them specific roles. Designate them as Managers, Testers, Checkers, and more."
    },
    {
        Id: Math.random(),
        Content: "<b>Product and Category Management:</b> Make detailed product pages and arrange your products as desired through quick categorization. Easily upload products in bulk, set sale prices, and more."
    },
    {
        Id: Math.random(),
        Content: "<b>User-Friendly Order Management System:</b> Effortlessly view and fulfill all orders through a user-friendly dashboard, providing a quick overview of order statuses. Streamline tracking of inventory levels, orders, sales, and deliveries."
    },
    {
        Id: Math.random(),
        Content: "<b>Coupons and Promotions:</b> Boost sales through highly adaptable promotions, limited-time coupon codes, timed promotions, and more."
    },
    {
        Id: Math.random(),
        Content: "<b>Marketing and SEO Tools:</b> Use the power of cutting-edge SEO and marketing strategies to connect with new customers and encourage their repeat business."
    },
    {
        Id: Math.random(),
        Content: "<b>Inventory Management:</b> Reliably monitor stock levels and receive timely alerts when inventory is running low with our Inventory Control feature."
    },
    {
        Id: Math.random(),
        Content: "<b>Robust Shipping Tools:</b> Display real-time shipping rates to your customers and print labels directly from your dashboard. Keeping track of the orders and their delivery stages."
    },
    {
        Id: Math.random(),
        Content: "<b>Local/International Cards Accepted:</b> Your online store will be automatically set up to accept credit card payments via WEBBEECART integrated payment gateway, without any extra work or setup on your part. Start selling from day one!"
    },
    {
        Id: Math.random(),
        Content: "<b>Abandoned Carts Recovery:</b> Our Abandoned Cart recovery tool enables you to encourage customers to return and complete their unfinished orders."
    },
    {
        Id: Math.random(),
        Content: "<b>Data Protection & Confidentiality:</b> We use industrial-level security features to ensure your customers' information including Personal, bank, card details, and addresses are confidential and secure."
    }

]