const demo = {
    baseUrl: 'https://api.demowbs.com',
    portalUrl: 'https://portal.demowbs.com',
}

const main = {
    baseUrl: 'https://api.webbeesite.com',
    portalUrl: 'https://portal.webbeesite.com',
}

const isDev = false;

export const environment = isDev ? demo : main