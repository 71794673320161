

import React, { useState, useEffect } from 'react'
import Logo from '../Resources/Logo.webp'
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { motion } from "framer-motion";
import { Route, Routes, Link } from 'react-router-dom';
import './index.css'

const Seperator = ({ item }) => {







    return (

        <div className='h-0.5 w-[10%] lg:w-[12%] md:my-3 bg-[#F2B307]' data-aos='slide-up' data-aos-offset='100' data-aos-deleay='200' data-aos-once="true"></div>
    )
}





export default Seperator



