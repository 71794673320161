import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from "../Components/Reuseable/Breadcrumbs";
import JobsBox from "../Components/Careers/JobsBox";
import JobsDescription from "../Components/Careers/JobsDescription";
import { environment } from '../environment';

const Careers = () => {

	const [jobsDatas, setJobsDatas] = useState([]);
	const [activeDescription, setActiveDescription] = React.useState({})

	const fetachAllJobs = useCallback(async () => {
		const requestOptions = 
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(
				{
					"search": "",
					"pageNumber": 1,
					"pageSize": 10
				})
		}

		const path = `${environment.baseUrl}/api/Customer/Careers/Jobs`

		fetch(path, requestOptions)
		.then((response)=>{
			if(response.status === 200)
			{
				response.json().then((result)=>{
					setActiveDescription(result.Result[0])
					setJobsDatas(result.Result) 
				})
			}
		})
		.catch((err)=>{
			console.log('err => ', err)
		})
	},[])

	const sortAscendingDescendingOrder = (e) => 
	{
		let sort = e.target.value
		let sortedData = []
		if(sort === "Ascending")
		{
			sortedData = jobsDatas.sort((a, b) => (a.Title > b.Title) ? 1 : -1)
		}
		else if(sort === "Descending")
		{
			sortedData = jobsDatas.sort((a, b) => (a.Title < b.Title) ? 1 : -1)
		}
		else
		{
			sortedData = jobsDatas
		}
		setJobsDatas(sortedData)
		setActiveDescription(sortedData[0])
	}
	
	useEffect(()=>{ 
		window.scrollTo(0, 0)
		fetachAllJobs()
	},[
		fetachAllJobs
	])
	// console.log('jobsDatas => ', jobsDatas)

	const checkFunction = (e) => 
	{
		setActiveDescription(e)
		// console.log('e => ', e)
	}

	const datas = [
		{
			id: 1,
			heading: "1 Assistant Manager",
			des: "1 Lorem ipsum dolor, sit amet consectetur adipisicing elit."
		},
		{
			id: 2,
			heading: "2 Assistant Manager",
			des: "2 Lorem ipsum dolor, sit amet consectetur adipisicing elit."
		},
		{
			id: 3,
			heading: "3 Assistant Manager",
			des: "3 Lorem ipsum dolor, sit amet consectetur adipisicing elit."
		},
	]

	return (
		<main className='xl:pt-20 bg-[#FFFFFF] pb-12'>
			<div className='w-[90%] xl:w-[80%] mx-auto xl:flex'>
				<div className='w-full flex flex-col justify-between items-center xl:items-start'>
					<div className='pt-[6rem] lg:pt-16'>
						<Breadcrumbs PageName={"JOIN OUR TEAM"} />
					</div>
					<p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl text-center xl:text-left mt-3 xl:w-[60%]'>
						Join <br />
						Our
						<span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.4rem] bg-[length:100%_25%]'>
							Team
						</span>
					</p>
					<div className='h-1 xl:h-[2px] bg-[#F2B307] w-24 xl:w-[13%] my-4 mx-auto xl:mx-0'></div>
					<p className='font-outfit font-semibold text-black text-justify'>
						At <span className="text-gkYellow font-bold">WEBBEESITE</span>, we're not just building a team – we're fostering a diverse and inclusive community where all individual’s unique talents and perspectives contribute to our success. Are you ready to contribute, learn, and grow? Explore our open positions and discover your potential with us.
					</p>

					<div className='relative'>
						<select className='font-outfit rounded w-60 xl:w-[37rem] p-3 bg-black/5 mt-4 appearance-none'
							onChange={(e)=>{sortAscendingDescendingOrder(e)}}
							>
							<option selected>Sort</option>
							<option value={'Ascending'}>Ascending</option>
							<option value={'Descending'}>Descending</option>
						</select>

						<svg
							xmlns='http://www.w3.org/2000/svg'
							height='0.8em'
							viewBox='0 0 320 512'
							className='absolute top-8 right-4'>
							<path d='M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z' />
						</svg>
					</div>

					{jobsDatas && jobsDatas.length ? 
						<section className='font-outfit mt-8 xl:flex xl:justify-between w-full'>
							{/* Content Lists */}
							<JobsBox checkFunction={checkFunction} datas={datas} jobsDatas={jobsDatas}/>
							{/* Content View */}
							<JobsDescription activeDescription={activeDescription}/>
						</section> : 
						<div className="w-full h-[40vh] mt-10 bg-gray-100 flex flex-col justify-center items-center rounded-xl">
							<p className="text-gray-500 font-outfit font-bold text-xl">No Jobs</p>
							<p className="text-gray-400 font-outfit">There are no jobs available right now</p>
						</div>
					}
				</div>
			</div>
		</main>
	);
};

export default Careers;
