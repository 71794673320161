import React, { useEffect } from "react";
import Breadcrumbs from "../Components/Reuseable/Breadcrumbs";
import Questions from "../Components/Faq/Question";

const Faq = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	  }, []);
	return (
		<main className='pb-10 xl:py-20 bg-[#FFFFFF]'>
			<div className='w-[90%] xl:w-[80%] mx-auto xl:flex'>
				<div className='w-full flex flex-col justify-between xl:items-start'>
					<div className='pt-[6rem] lg:pt-16'>
						<Breadcrumbs PageName={"FREQUENTLY ASK QUESTIONS"} />
					</div>
					<p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl text-center xl:text-left mt-3 xl:w-[60%]'>
						Frequently Asked <br />
						<span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.4rem] bg-[length:100%_25%]'>
							Questions
						</span>
					</p>
					<div className='h-1 xl:h-[2px] bg-[#F2B307] w-24 xl:w-[13%] my-4 mx-auto xl:mx-0'></div>
					<p className='font-outfit font-semibold text-black text-justify mb-6'>
						Got questions? We've got answers. Explore our FAQs to find quick solutions and insights about <span className="text-gkYellow font-bold">WEBBEESITE</span>, and the services we offer.
					</p>
                    <Questions/>
				</div>
			</div>
		</main>
	);
};

export default Faq;
