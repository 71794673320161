import React, { useEffect } from "react";

import AboutMainBanner from "../Components/Reuseable/AboutMainBanner";
import StepsTile from "./StepsTile";
import BecomSellerDesc from "../Components/BecomeSeller/BecomSellerDesc";

import becomeSellerIcon1 from "../Resources/feature7.webp";
import becomeSellerIcon2 from "../Resources/becomeSellerIcon2.webp";
import becomeSellerIcon3 from "../Resources/becomeseller-icon3.webp";

import { StartBuildingWebsiteForm } from "../Components/BecomeSeller/create-website-form";
// import SampleRegisterSteps2 from "../Resources/becomesellericonwhite2.webp";
// import becomeSellerIconwhite1 from "../Resources/becomesellericonwhite1.webp";
// import becomeSellerIconwhite3 from "../Resources/becomesellericonwhite3.webp";

const SampleStepsTileData = [
	{
		Id: Math.random(),
		Count: "01",
		Title: `Fill up the registration form`,
		Image: becomeSellerIcon1,
	},
	{
		Id: Math.random(),
		Count: "02",
		Title: "Log in to the customer portal",
		Image: becomeSellerIcon2,
	},
	{
		Id: Math.random(),
		Count: "03",
		Title: "Upload your documents",
		Image: becomeSellerIcon3,
	},
];

// const SampleRegisterSteps = [
// 	{
// 		Id: Math.random(),
// 		Count: "01",
// 		Title: `Fill in the client's business information`,
// 		Image: becomeSellerIconwhite1,
// 		colorCondition: true,
// 	},
// 	{
// 		Id: Math.random(),
// 		Count: "02",
// 		Title: "Select a package that suits you",
// 		Image: SampleRegisterSteps2,
// 		colorCondition: true,
// 	},
// 	{
// 		Id: Math.random(),
// 		Count: "03",
// 		Title: "Upload the content for the website and pay",
// 		Image: becomeSellerIconwhite3,
// 		colorCondition: true,
// 	},
// ];

const CreateWebsite = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<section className='w-full flex flex-col items-center bg-[#FFFFFF] '>
			<div className='w-full xl:pb-12'>
				<AboutMainBanner
					aboutUs
					PathRoute={"BECOME A CUSTOMER"}
					AboutWhat='Website Creation Simplified with the Best Website Development Company in UAE'
					Desc= {"Take a deep breath  you're about to experience website creation like never before."}
					Desc2= {"Step into Web Success with WEBBEESITE: Your Journey, Simplified! Now you can build, manage, and customize your website."}
					Desc3= {["All this awesomeness starts from just ",<span className="text-gkYellow font-bold">“AED 1499”</span>,  " Let's make web magic happen!"]}
					// showbuttun = {false}
					pmHideButton = {true}

					typeof='BecomeSeller' 
					clicked={() => {
						const element = document.getElementById("websiteform");
						if (element) {
							// 👇 Will scroll smoothly to the top of the next section
							element.scrollIntoView({ behavior: "smooth" });
						}
					}}
				/>
			</div>
			{/* <Beehivecontainer />
      <div className="pt-[30px]">
        <ServicesRecommending />
      </div> */}
			<div className='w-[90%] xl:w-[80%] mx-auto mt-16 xl:mt-6'>
				<p className='font-outfit font-extrabold text-[#000000] text-3xl xl:text-5xl text-center'>
					Register with These{" "}
					<span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]'>
						Steps
					</span>
				</p>
				<div className='h-1 xl:h-[2px] bg-[#F2B307] w-40 xl:w-[7%] my-3 mx-auto'>
					{" "}
				</div>
				<p className='font-outfit font-extrabold text-[18px] xl:text-[15px] text-[#000000] text-center'>
					So, what are you waiting for? Register with us in three simple steps:
				</p>
			</div>

			<div className='flex flex-row w-[80%] xl:justify-between xl:items-center mx-auto overflow-x-scroll xl:overflow-x-auto scrollbar-none mt-6 xl:mt-0'>
				{SampleStepsTileData?.map((item, index) => {
					return (
						<StepsTile
							Title={item.Title}
							Count={item.Count}
							Image={item.Image}
							// condition={item.colorCondition}
						/>
					);
				})}
			</div>

			<div className='w-[90%] xl:w-[80%] mx-auto mt-7 xl:mt-1'>
				<p className='text-[18px] xl:text-[15px] font-outfit font-bold text-justify xl:text-center text-[#000000]'>
					Our team will carefully review your application and process it within
					48 hours. If your application is approved, then you will receive an
					email confirming your acceptance and a dedicated backend portal to
					help you get started.
				</p>
			</div>

			<div id='websiteform' className='w-[90%] xl:w-[80%] mx-auto'>
				{/* <BecomeSellerForm
					title={"Start Building Your Website Now"}
					TextBox1Title={"Bussiness Name"}
					CreateWebsite={true}
					createWebsite={true}
				/> */}
				<StartBuildingWebsiteForm />
			</div>

			{/* <div className='bg-hooray-bg bg-cover py-16 xl:py-12 w-full'>
				<div className='w-[90%] xl:w-[80%] mx-auto xl:mt-0'>
					<p className='font-outfit font-extrabold text-[#000000] text-3xl xl:text-4xl text-center'>
						<span className='bg-rectangle-white bg-no-repeat bg-[center_bottom_0.2rem] bg-[length:100%_40%]'>
							Hoo
						</span>
						ray!
					</p>
					<p className='font-outfit font-medium text-[15px] mt-3 text-center xl:text-[20px] text-[#000000]'>
						Start creating your customer profile for website development.
					</p>
					<div className='h-1 xl:h-[2px] bg-[#FFFFFF] w-40 xl:w-[7%] my-4 mx-auto'>
						{" "}
					</div>
				</div>

				<div className='w-[90%] xl:w-[75%] mx-auto mt-6'>
					<p className='font-outfit font-extrabold text-[#000000] text-3xl xl:text-4xl text-center'>
						How to Register{" "}
						<span className='bg-rectangle-white bg-no-repeat bg-[center_bottom_0.2rem] bg-[length:100%_40%]'>
							a Client
						</span>
						?
					</p>
					<p className='font-outfit font-medium text-[15px] mt-4 text-center'>
						So, what are you waiting for? Register with us in three simple
						steps:
					</p>
				</div>

				<div className='flex flex-row w-[80%] xl:justify-between xl:items-center mx-auto overflow-x-scroll xl:overflow-x-auto scrollbar-none'>
					{SampleRegisterSteps?.map((item, index) => {
						return (
							<StepsTile
								Title={item.Title}
								Count={item.Count}
								Image={item.Image}
							/>
						);
					})}
				</div>
			</div> */}

      <p className='font-outfit font-extrabold pt-6 xl:pt-10 text-[#000000] text-3xl xl:text-5xl text-center w-[85%] xl:w-[50%]'>
      Start building your dream website with{" "}
					<span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]'>
						three simple steps!
					</span>
				</p>
				<div className='h-1 xl:h-[2px] bg-[#F2B307] w-40 xl:w-[7%] my-3 mx-auto'>
					{" "}
				</div>

			<div className='bg-bs-desc-bg bg-cover bg-bottom'>
				<div className='w-[90%] xl:w-[80%] mx-auto mt-4 py-8 xl:py-14 '>
					<BecomSellerDesc
						heading={"Register and Launch Your Journey"}
						description={
							"Embrace the beginning of your web adventure! Sign up as a client by completing our quick and straightforward registration form."
						}
						image={"polygonNo1"}
					/>

					<BecomSellerDesc
						heading={"Your Backstage Pass: Credentials Await!"}
						description={
							"Your anticipation pays off as we hand you the keys to your very own backend portal. Check your inbox for the credentials to our user-friendly backend interface."
						}
						image={"polygonNo2"}
					/>

					<BecomSellerDesc
						heading={"Build, Modify, Repeat"}
						description={
							"Log in using the provided credentials and step into a world of possibilities. Select package that resonate with your brand and start building your website, the canvas is limitless."
						}
						image={"polygonNo3"}
					/>
				</div>
			</div>
      <div className="flex flex-col items-center pb-10">
      <h1 className='font-outfit font-extrabold text-[#000000] text-3xl xl:text-5xl text-center'>
					<span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]'>
          Congratulations
					</span>
				</h1>
				<div className='h-1 xl:h-[2px] bg-[#F2B307] w-40 xl:w-[7%] my-3 mx-auto'>
					{" "}
				</div>
        <p className="xl:w-[70%] text-[18px] xl:text-[15px] font-outfit font-medium text-center xl:text-center text-[#000000]">you're now a buzzing member of the WEBBEESITE community! Your website speaks volumes about your brand, and the digital realm is yours to conquer.</p>
      </div>
		</section>
	);
};

export default CreateWebsite;
