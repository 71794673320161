import React, { useMemo } from 'react'
import PhoneIcon from "../../Resources/phone-icon.webp"
import EmailIcon from "../../Resources/email-icon.webp"
import LocationIcon from "../../Resources/location-icon.webp"
import ExternalLinkIcon from "../../Resources/external-link.webp"

const Infobox = (props) => {

  const isPhone = useMemo(() => props.image === 'Phone' ,[props.image]);
  const isEmail = useMemo(() => props.image === 'Email' ,[props.image]);

  const imageSrc = useMemo(() => isPhone ? PhoneIcon : isEmail ? EmailIcon : LocationIcon ,[isEmail, isPhone]);

  return (
    <div>
        <img src={imageSrc} alt="icon"  className={props.image === 'Location' ? 'h-14' : 'w-12'}/>
        <h1 className='text-[#000000] font-outfit font-extrabold mt-2 text-[17px]'>{props.heading}</h1>
        {isPhone || isEmail ? 
          <>
            <a className="block w-fit" href={isPhone ? `tel:${props.text1}` : `mailto:${props.text1}`}><p className={`w-fit text-[#000000] font-outfit font-medium text-sm cursor-pointer hover:underline`}>{props.text1}</p></a>
            <a className="block w-fit" href={isPhone ? `tel:${props.text2}` : `mailto:${props.text2}`}><p className={`w-fit text-[#000000] font-outfit font-medium text-sm cursor-pointer hover:underline`}>{props.text2}</p></a>
            <a className="block w-fit" href={isPhone ? `tel:${props.text3}` : `mailto:${props.text3}`}><p className={`w-fit text-[#000000] font-outfit font-medium text-sm cursor-pointer hover:underline`}>{props.text3}</p></a>
          </>
          :
          <>
            <p className={`text-[#000000] font-outfit font-medium text-sm ${props.image === ''}`}>{props.text1}</p>
            <p className={`text-[#000000] font-outfit font-medium text-sm ${props.image === ''}`}>{props.text2}</p>
            <p className={`text-[#000000] font-outfit font-medium text-sm ${props.image === ''}`}>{props.text3}</p>
          </> 
        }
        
        {
            props.image === 'Location'
            ?
            <div className='flex items-center mt-[6px]'>
              <a className='flex items-center' href={props.test} target="_blank" rel="noreferrer">
                <p className='font-outfit text-[15px] xl:text-[13px] cursor-pointer font-bold text-[#F2B307]'>VIEW ON GOOGLE MAPS</p>
                <img src={ExternalLinkIcon} alt="Link" className='h-4 ml-2 cursor-pointer'/>
              </a>
            </div>
            :
            ''
        }
    </div>
  )
}

export default Infobox