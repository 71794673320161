import React from "react";
import Breadcrumbs from "../../Components/Reuseable/Breadcrumbs";
import Seperator from "../Seperator";

import aboutUsClipImage from "../../Resources/aboutUsClipImages.webp";
import becomesellerHeroImg from "../../Resources/BecomeSeller-hero-img.webp";
import b2bHeroImg from "../../Resources/b2b-hero.webp"

import Bookdemocard from "../Bookdemocard";
import Scrolltoform from "../Scrolltoform";

const AboutMainBanner = (props) => {

  return (
    <div className="flex flex-col-reverse lg:flex-row mt-24 lg:w-[90%] xl:w-[80%] py-10 xl:py-0 mx-auto">
      <div className="w-full items-center lg:items-start lg:w-[50%] flex flex-col xl:ml-4">
        <div className="pt-5 lg:pt-12">
          <Breadcrumbs PageName={props.PathRoute} />
        </div>
        <p className="font-outfit font-extrabold text-center lg:text-left text-[32px] text-[#000000] pt-1">
          {props.AboutWhat}
          <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">
            {props.AboutWhat3}
          </span>
        </p>
        <p className="font-outfit font-extrabold text-center lg:text-left text-3xl md:text-4xl xl:text-5xl text-[#000000] pb-1">
          <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">
            {props.AboutWhat1}
          </span>
          {props.AboutWhat2}
        </p>
        <Seperator />
        <div className="pt-1 mb-3">
          <p className="font-outfit font-medium text-center lg:text-left px-5 lg:px-0 lg:w-[80%] xl:w-full text-[15px] md:text-[17px] text-[#000000]">
          {props.Desc} <br />{props.Desc2} <br />{props.Desc3}</p>
        </div>
        {/* <button
					type='button'
					className='bg-[#F2B307] py-3 text-sm rounded-md mt-4 text-white w-[65%] md:w-[50%] xl:w-[30%] font-manrope'
					onClick={() => {
						dispatch(setShowpopUp(true));
					}}
				>
					Book Your Demo Now
				</button> */}
        {(props?.showbuttun !== false && !props.pmHideButton) && (
          // <Bookdemocard background={"bg-[#000000] text-white"} background2={"bg-[#FFBB00]"}/>
          <Scrolltoform
            title={props.PathRoute}
            click={props.clicked}
            background={"bg-[#000000] text-white"}
            background2={"bg-[#FFBB00]"}
          />
        )}

        {/* {props.pmHideButton == true ? props.showbuttun == false : props.showbuttun} */}

        {props?.showbuttun === false && (
          // <Bookdemocard background={"bg-[#000000] text-white"} background2={"bg-[#FFBB00]"}/>
          <Bookdemocard  background={"bg-[#000000] text-white"} background2={"bg-[#FFBB00]"}/>
          // <Bookdemocardnew
          //   background={"bg-[#000000] text-white"}
          //   background2={"bg-[#FFFFFF]"}
          // />
        )}
      </div>
      <div className="w-full lg:w-[50%] flex justify-center xl:justify-end mt-14 xl:mr-4">
        <img
          src={
            props.typeof === "BecomeSeller" && props.pmHideButton === false
            ? becomesellerHeroImg : props.typeof === "BecomeSeller" && props.pmHideButton === true ? b2bHeroImg
            : aboutUsClipImage
          }
          className="w-[300px] md:w-[450px] xl:w-[480px]"
          alt=""
        />
      </div>
    </div>
  );
};

export default AboutMainBanner;
