import React, { useEffect } from "react";
import Breadcrumbs from "../Components/Reuseable/Breadcrumbs";

const categories = [
  {
    title: "PRIVACY POLICY:",
    content: `At WEBBEE TECHNOLOGY, the privacy of our visitors is of extreme importance to us. This privacy policy document outlines the types of personal information that are received and collected by WEBBEE TECHNOLOGY and how they are used. \nBy accessing this website, we assume you accept these terms and conditions. Do not continue to use WEBBEESITE if you do not agree to take all of the terms and conditions stated on this page. \nThe following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements: "Client," "You," and "Your" refer to you, the person who logs on to this website and complies with the Company's terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us" refer to our Company. "Party," "Parties," or "Us" refer to both the Client and ourselves.`,
  },
  {
    title: "LICENSE",
    content: "Unless otherwise stated, WEBBEESITE and/or its licensors own the intellectual property rights for all material on WEBBEESITE. All intellectual property rights are reserved. You may access this from WEBBEESITE for your own personal use, subject to the restrictions set in these terms and conditions.",
    secondarytitle: "You must not:",
    secondarycontent: `. Republish material from WEBBEESITE \n. Sell, rent, or sublicense material from WEBBEESITE \n. Reproduce, duplicate, or copy material from WEBBEESITE \n. Redistribute content from WEBBEESITE`,
  },
  {
    title: "LOG FILES:",
    content: `Like many other websites, WEBBEE TECHNOLOGY makes use of log files. The information inside the log files includes internet protocol (IP) addresses, the type of browser, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and the number of clicks to analyze trends, administer the site, track users' movement around the site, and gather demographic information. IP addresses and other such information are not linked to any personally identifiable information.`,
  },
  {
    title: "PRIVACY POLICY:",
    content: `WEBBEE TECHNOLOGY does not use cookies. \nThird-party cookies, ad servers, or ad networks use technology to deliver the advertisements and links that appear on WEBBEE TECHNOLOGY directly to your browsers. They automatically receive your IP address when this occurs. Other technologies (such as cookies, JavaScript, or Web Beacons) may also be used by the third-party ad networks to measure the effectiveness of their advertisements and/or to personalize the advertising content that you see. \nWEBBEE TECHNOLOGY has no access to or control over these cookies that are used by third-party advertisers. \nYou should consult the respective privacy policies of these third-party ad servers for more detailed information on their practices, as well as for instructions on how to opt-out of certain practices. \nWEBBEE TECHNOLOGY's privacy policy does not apply to, and we cannot control the activities of, such other advertisers or websites. \nIf you wish to disable cookies, you may do so through your individual browser options. More detailed information about cookie management with specific web browsers can be found on the browsers' respective websites. \nIf you require any more information or have any questions about our privacy policy, please feel free to contact us by email at info@webbeesite.com.`,
  },
];

const PrivacyPolicy = () => {

  function NewlineText(props) {
    const text = props;
    const newText = text
      .split("\n")
      .map((str, index) =>
        index === 0 ? <p>{str}</p> : <p className="mt-2">{str}</p>
      );

    return newText;
  }

  function NewbulletlineText(props) {
    const text = props;

    const newText = text
      .split("\n")
      .map((str, index) =>
        index === 0 ? <p>{str}</p> : <p className="mt-2">{str}</p>
      );

    return newText;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="pt-16 xl:pt-[5.5rem] pb-12 bg-[#FFFFFF]">
      <div className="w-full h-[200px] flex items-center justify-center pt-10 xl:pt-0 ">
        <div>
          <div className="flex justify-center">
            <Breadcrumbs PageName={"PRIVACY POLICY"} />
          </div>
          <p className="font-outfit text-black font-extrabold mt-4 text-3xl xl:text-4xl text-center xl:text-left ">
            <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_50%]">
              Privacy
            </span>{" "}
            Policy
          </p>
          <div
            className="h-0.5 w-[70%] lg:w-[12%] xl:w-[30%] mt-4 mx-auto bg-[#F2B307] flex justify-center items-center"
            data-aos="slide-up"
            data-aos-offset="100"
            data-aos-deleay="200"
            data-aos-once="true"
          ></div>
        </div>
      </div>

      <div className="w-[90%] xl:w-[80%] mx-auto px-2 xl:px-6">
        {/* <p className='font-outfit font-bold text-xl md:text-2xl xl:text-[16px] text-[#000000] pt-5 xl:mt-1'>WEBBEE TECHNOLOGY <span className='font-outfit font-medium text-black text-base xl:text-[15px] text-justify '>does not have any hidden terms and conditions. WEBBEE TECHNOLOGY provides a legal digital contract to you with all the terms and conditions.</span></p> */}

        {categories.map((item, index) => {
          return (
            <>
              <p className="font-outfit font-bold text-xl md:text-2xl xl:text-[16px] text-[#000000] pt-5 xl:mt-1">
                {item.title}
              </p>
              <div className="font-outfit font-medium text-black text-base xl:text-[15px] text-justify ">
                {NewlineText(item.content)}
              </div>

              {item?.secondarytitle && (
                <p className="font-outfit font-bold text-xl md:text-2xl xl:text-[14px] text-[#000000] pt-5 xl:mt-1 ">
                  {item?.secondarytitle}
                </p>
              )}
              {item?.secondarycontent && (
                <div className="font-outfit font-medium text-black text-base xl:text-[13px] text-justify ml-5">
                  {NewbulletlineText(item?.secondarycontent)}
                </div>
              )}
            </>
          );
        })}
      </div>
    </section>
  );
};

export default PrivacyPolicy;
