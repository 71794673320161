import React, { useState, useEffect } from "react";
import ContactForm from "../Components/Contact/ContactForm";
import Breadcrumbs from "../Components/Reuseable/Breadcrumbs"
import Infobox from "../Components/Contact/Infobox"
import { environment } from "../environment";

// const baseURL = "https://api.webbeesite.com";
const baseURL = environment.baseUrl;

const Contact = () => {
  const [ContactDetails, setContactDetails] = useState("")
  
  useEffect(() => {
    FetchContactDetails()
    window.scrollTo(0, 0)
  }, []);
  
  
  const FetchContactDetails = () => {
    
    


		const requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			}
		};

		fetch(`${baseURL}/api/BusinessSetting/Master`, requestOptions).then(
			(response) => {
				response
					.json()
					.then((data) => {
						console.debug('check data here =>', data.Result[0]);
            setContactDetails(data.Result[0])
					})
					.catch((badResponse) => {
						console.debug('check badresponse here => ', badResponse)
					});
			}
		);
	};

	return (
		// bg-gradient-to-b from-[#FFBB002E] via-[#FFFFFF00]
    <section className="xl:pt-20 bg-[#FFFFFF]">
      <div className="w-[90%] xl:w-[80%] mx-auto xl:flex">
        <div className="w-full xl:w-[55%]">
          <div className="pt-[6rem] lg:pt-[4rem]">
            <Breadcrumbs PageName={'CONTACT US'}/>
          </div>
          <p className='text-[#000000] font-outfit font-extrabold text-3xl xl:text-4xl text-center xl:text-left mt-3 xl:w-[60%]'>
            Send us a <span className='bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.4rem] bg-[length:100%_25%]'>message</span> or get in touch
          </p>
          <div className='h-1 xl:h-[2px] bg-[#F2B307] w-40 xl:w-[13%] my-4 mx-auto xl:mx-0'> </div>

          <section>
            <div className="flex flex-wrap justify-center items-center mt-8">
              <div className="w-1/2">

                {/* <  image={'Phone'} heading={'Customer Service'} text1={ContactDetails?.Contact} text2={ContactDetails?.Contact2}/> */}
                <Infobox image={'Phone'} heading={'Customer Service'} text1={'+971 54 528 4110'} text2={'+971 54 528 1135'} text3={'+971 4 332 0780'}/>
              </div>
              <div className="w-1/2">

                <Infobox image={'Email'} heading={'Email Address'} text1={ContactDetails?.Email} text2={ContactDetails?.Email2}/>
              </div>
              <div className="w-full mt-6">
                <Infobox 
                image={'Location'} 
                heading={'Our Location'} 
                text1={"Office # 702, B8 Building, Al Barsha-1, UAE, United Arab Emirates."} 
                test={"https://www.google.com/maps/place/WeBBeeSite+Technology+%7C+IT+Services/@25.1134018,55.2030081,15z/data=!4m14!1m7!3m6!1s0x3e5f6b8aa6c386ff:0x3bfff29042934825!2sWeBBeeSite+Technology+%7C+IT+Services!8m2!3d25.1134018!4d55.2030081!16s%2Fg%2F11sw4vf5s_!3m5!1s0x3e5f6b8aa6c386ff:0x3bfff29042934825!8m2!3d25.1134018!4d55.2030081!16s%2Fg%2F11sw4vf5s_?entry=ttu"}/>
              </div>
            </div>
          </section>
        </div>
        <div className="w-full xl:w-[45%]">
          <ContactForm/>
        </div>

      </div>
    </section>
		
	);
};

export default Contact;
