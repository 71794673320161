import React from "react";
import {BecomeSellerForm} from "../Components/BecomeSeller/become-seller-form";

import ServicesRecommending from "../Components/BecomeSeller/ServicesRecommending";
import AboutMainBanner from "../Components/Reuseable/AboutMainBanner";
import StepsTile from "./StepsTile";
import BecomSellerDesc from "../Components/BecomeSeller/BecomSellerDesc";

import becomeSellerIcon1 from "../Resources/feature7.webp";
import becomeSellerIcon2 from "../Resources/becomeSellerIcon2.webp";
import becomeSellerIcon3 from "../Resources/becomeseller-icon3.webp";

import SampleRegisterSteps2 from "../Resources/becomesellericonwhite2.webp";
import becomeSellerIconwhite1 from "../Resources/becomesellericonwhite1.webp";
import becomeSellerIconwhite3 from "../Resources/becomesellericonwhite3.webp";

const SampleStepsTileData = [
  {
    Id: Math.random(),
    Count: "01",
    Title: `Fill up the registration form`,
    Image: becomeSellerIcon1,
  },
  {
    Id: Math.random(),
    Count: "02",
    Title: "Log in to the vendor portal",
    Image: becomeSellerIcon2,
  },
  {
    Id: Math.random(),
    Count: "03",
    Title: "Upload your documents",
    Image: becomeSellerIcon3,
  },
];

const SampleRegisterSteps = [
  {
    Id: Math.random(),
    Count: "01",
    Title: `Fill in the client's business information`,
    Image: becomeSellerIconwhite1,
    colorCondition: true,
  },
  {
    Id: Math.random(),
    Count: "02",
    Title: "Select a package that suits you",
    Image: SampleRegisterSteps2,
    colorCondition: true,
  },
  {
    Id: Math.random(),
    Count: "03",
    Title: "Upload the content for the website and pay",
    Image: becomeSellerIconwhite3,
    colorCondition: true,
  },
];

const BecomeSeller = () => {

  return (
    <section className="w-full flex flex-col items-center bg-[#FFFFFF] ">
      <div className="w-full xl:pb-12">
        <AboutMainBanner
          aboutUs
          PathRoute={"BECOME A SELLER"}
          AboutWhat="Business "
          AboutWhat3=" Referral"
          AboutWhat2="Program"
          Desc={["Become a ", <span className="text-gkYellow font-bold">&nbsp;WEBBEESITE &nbsp;</span>, "seller and start earning today!"]}
          Desc2={"Earn up to AED 1000 from every sale. Our website development services are here to support your business growth."}
          pmHideButton = {false}
          typeof="BecomeSeller"
          clicked={() => {
            const element = document.getElementById("sellerform");
            if (element) {
              // 👇 Will scroll smoothly to the top of the next section
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
        />
      </div>
      {/* <Beehivecontainer /> */}
      <div className="pt-[30px]">
        <ServicesRecommending />
      </div>
      <div className="w-[90%] xl:w-[80%] mx-auto mt-16 xl:mt-6">
        <p className="font-outfit font-extrabold text-[#000000] text-3xl xl:text-4xl text-center">
          Register with These{" "}
          <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">
            Steps
          </span>
        </p>
        <div className="h-1 xl:h-[2px] bg-[#F2B307] w-40 xl:w-[7%] my-3 mx-auto">
          {" "}
        </div>
        <p className="font-outfit font-medium text-[18px] xl:text-[15px] text-[#000000] text-center">
          So, what are you waiting for? Register with us in three simple steps:
        </p>
      </div>

      <div className="flex flex-row w-[80%] xl:justify-between xl:items-center mx-auto overflow-x-scroll xl:overflow-x-auto scrollbar-none mt-6 xl:mt-0">
        {SampleStepsTileData?.map((item, index) => {
          return (
            <StepsTile
              Title={item.Title}
              Count={item.Count}
              Image={item.Image}
              // condition={item.colorCondition}
            />
          );
        })}
      </div>

      <div className="w-[90%] xl:w-[80%] mx-auto mt-7 xl:mt-1">
        <p className="text-[18px] xl:text-[15px] font-outfit font-medium text-justify xl:text-center text-[#000000]">
          Our team will carefully review your application and process it within
          48 hours. If your application is approved, then you will receive an
          email confirming your acceptance and a dedicated backend portal to
          help you get started.
        </p>
      </div>

      <div id="sellerform" className="w-[90%] xl:w-[80%] mx-auto">
        {/* <BecomeSellerForm allowdirectlogin={true} /> */}
        <BecomeSellerForm />
      </div>

      <div className="bg-hooray-bg bg-cover py-16 xl:py-12 w-full">
        <div className="w-[90%] xl:w-[80%] mx-auto xl:mt-0">
          <p className="font-outfit font-extrabold text-[#000000] text-3xl xl:text-4xl text-center">
            <span className="bg-rectangle-white bg-no-repeat bg-[center_bottom_0.2rem] bg-[length:100%_40%]">
              Hoo
            </span>
            ray!
          </p>
          <p className="font-outfit font-medium text-[15px] mt-3 text-center xl:text-[20px] text-[#000000]">
          Start creating your customers profile and provide them with the best web design in Dubai!
          </p>
          <div className="h-1 xl:h-[2px] bg-[#FFFFFF] w-40 xl:w-[7%] my-4 mx-auto">
            {" "}
          </div>
        </div>

        <div className="w-[90%] xl:w-[75%] mx-auto mt-6">
          <p className="font-outfit font-extrabold text-[#000000] text-3xl xl:text-4xl text-center">
            How to Register{" "}
            <span className="bg-rectangle-white bg-no-repeat bg-[center_bottom_0.2rem] bg-[length:100%_40%]">
              a Client
            </span>
            ?
          </p>
          <p className="font-outfit font-medium text-[15px] mt-4 text-center">
            So, what are you waiting for? Register with us in three simple
            steps:
          </p>
        </div>

        <div className="flex flex-row w-[80%] xl:justify-between xl:items-center mx-auto overflow-x-scroll xl:overflow-x-auto scrollbar-none">
          {SampleRegisterSteps?.map((item, index) => {
            return (
              <StepsTile
                Title={item.Title}
                Count={item.Count}
                Image={item.Image}
              />
            );
          })}
        </div>
      </div>

      <div className="bg-bs-desc-bg bg-cover bg-bottom">
        <div className="w-[90%] xl:w-[80%] mx-auto mt-4 py-8 xl:py-14 ">
          <BecomSellerDesc
            description={
              "Our team will review the provided content and information and will begin working on the website setup. In case anything is missing, they will reach out via email or call and get the missing information completed. "
            }
            image={"polygonNo1"}
          />

          <BecomSellerDesc
            description={
              "You can track the work status by just going to the client details page through your dashboard. Once the website is set up and completed, you will be notified via email with the client backend link and login credentials."
            }
            image={"polygonNo2"}
          />

          <BecomSellerDesc
            description={
              "The back-end portal is very user-friendly and easy to use, allowing your clients to update logos, color themes, banners, add services, and update business information, etc."
            }
            image={"polygonNo3"}
          />
        </div>
      </div>
    </section>
  );
};

export default BecomeSeller;
