import React from "react";
import Breadcrumbs from "../Components/Reuseable/Breadcrumbs";
import webbeeclienthero from "../Resources/webbeeclientherobanner.webp";
import playstore from "../Resources/playstore.webp";
import appstore from "../Resources/appstore.webp";
import webbeeclientservices from "../Resources/webbeeclientservices.webp";
import Seperator from "../Components/Seperator";
// import Featurecard from "../Components/Featurecard";
import Bookdemocard from "../Components/Bookdemocardnew";
import { Link } from "react-router-dom";
import feature1 from "../Resources/wcfeature1.webp";
import feature2 from "../Resources/wcfeature2.webp";
import feature3 from "../Resources/wcfeature3.webp";
import feature4 from "../Resources/wcfeature4.webp";
import feature5 from "../Resources/wcfeature5.webp";
import feature6 from "../Resources/wcfeature6.webp";
import feature7 from "../Resources/wcfeature7.webp";
import feature8 from "../Resources/wcfeature8.webp";
import polyblack from "../Resources/polygon-black.webp";
import appstoreIcon from "../Resources/appstore-black.webp";
import playstoreIcon from "../Resources/googleplay-black.webp";

const Features = [
  {
    label: "Create items effortlessly",
    img: feature1,
    number: "01",
    desc: "Name your item, set the price, and add a description in just a few clicks for quick invoicing.",
  },
  {
    label: "Tailor your invoices",
    img: feature8,
    number: "02",
    desc: "Add discounts, taxes, and select the customer from your database with ease.",
  },
  {
    label: "Eco-friendly invoicing",
    img: feature2,
    number: "03",
    desc: "Send invoices in sustainable ways, including payment links for online payments, in-person payments through QR codes, and email invoicing for seamless transactions.",
  },
  {
    label: "Stay organized",
    img: feature3,
    number: "04",
    desc: "Keep track of paid and unpaid invoices, ensuring your financials are always in order.",
  },
  {
    label: "Manage customer packages",
    img: feature4,
    number: "05",
    desc: "Keep a record of the packages your customers purchase, whether paid or unpaid.",
  },
  {
    label: "Streamline quotes",
    img: feature5,
    number: "06",
    desc: "Store quotes sent to customers for a quick and easy reference.",
  },
  {
    label: "Stay on top of appointments",
    img: feature6,
    number: "07",
    desc: "Effortlessly manage customer appointments, including time slots and customer remarks, all within the app.",
  },
  {
    label: "Understand your customers",
    img: feature7,
    number: "08",
    desc: "Gain valuable insights into your customers' experiences with your services by monitoring their feedback.",
  },
];
function WebbeeClient() {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  return (
    <div className="w-full bg-white">
      <div className="w-full mt-24 bg-webbeeclient-hero-bg bg-top bg-no-repeat bg-[length:100%_280px] md:bg-[length:100%_380px] lg:bg-[length:100%_480px] xl:bg-[length:100%_450px] 2xl:bg-[length:100%_500px]">
        <div className="flex flex-col mt-24 lg:w-[90%] xl:w-[80%] py-10 xl:py-0 mx-auto">
          <div className="w-full items-center justify-center flex flex-col xl:ml-4 pb-5 lg:pb-12">
            <div className="pt-5 lg:pt-12">
              <Breadcrumbs PageName="WEBBEE CLIENT" />
            </div>
            <p className="font-outfit font-extrabold text-center lg:text-left text-[32px] text-[#000000] pt-1">
              <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">
                Webbee
              </span>
              <span className="ml-2">Client</span>
            </p>
            <Seperator />
          </div>
          <div className="w-full lg:w-[80%] mx-auto">
            <img
              src={webbeeclienthero}
              className="w-full h-full object-contain"
              alt="Webbee Client"
            />
          </div>
          {/* <div className="flex flex-col lg:flex-row justify-between items-center w-[90%] mx-auto mt-4 mb-8 gap-8 lg:gap-0">
            <div className="w-full lg:w-[40%] flex flex-row bg-[#FCF2E1] p-6 rounded-md gap-7">
              <div className="w-[50%]">
                <img
                  src={appstore}
                  className="w-full h-full object-contain"
                  alt="QR for AppStore"
                />
              </div>
              <div className="flex flex-col items-start justify-center text-[20px] font-outfit  leading-tight w-full">
                <p>Scan QR Code for AppStore Download</p>
              </div>
            </div>
            <div className="w-full lg:w-[40%] flex flex-row bg-[#FCF2E1] p-6 rounded-md gap-7">
              <div className="w-[50%]">
                <img
                  src={playstore}
                  className="w-full h-full object-contain"
                  alt="QR for PlayStore"
                />
              </div>
              <div className="flex flex-col items-start justify-center text-[20px] font-outfit  leading-tight w-full">
                <p>Scan QR Code for PlayStore Download</p>
              </div>
            </div>
          </div> */}
          <div>
            {/* <p className="font-outfit font-extrabold text-center text-[32px] text-black py-4">
            Scan QR Code
          </p> */}
            <p className="font-outfit font-extrabold text-center text-[32px] text-black py-4">
              Scan
              <span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%] ml-2">
                QR Code
              </span>
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-center w-[90%] mx-auto lg:w-full mt-4 mb-8 gap-8 lg:gap-40">
            <div className="w-full flex flex-row p-8 bg-[#FCF2E1] rounded-md gap-7">
              <div className="w-[50%]">
                <img
                  src={appstore}
                  className="w-full h-full object-contain"
                  alt="QR for AppStore"
                />
              </div>
              <div className="w-[90%] mx-auto">
                <img
                  src={appstoreIcon}
                  className="w-full h-full object-contain"
                  alt=""
                />
              </div>
              {/* <div className="flex flex-col items-start justify-center text-[20px] md:text-[34px] lg:text-[20px] font-outfit  leading-tight w-full 2xl:px-2">
                <p>Download for AppStore</p>
              </div> */}
            </div>
            <div className="w-full flex flex-row p-8 bg-[#FCF2E1] rounded-md gap-7">
              <div className="w-[50%]">
                <img
                  src={playstore}
                  className="w-full h-full object-contain"
                  alt="QR for PlayStore"
                />
              </div>
              {/* <div className="flex flex-col items-start justify-center text-[20px] md:text-[34px] lg:text-[20px] font-outfit  leading-tight w-full 2xl:px-2">
                <p>Download for Playstore</p>
              </div> */}
              <div className="w-[90%] mx-auto">
                <img
                  src={playstoreIcon}
                  className="w-full h-full object-contain"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="mt-4 mb-8 lg:mt-6 w-[90%] mx-auto flex flex-col lg:flex-row lg:items-center justify-center lg:gap-4">
            <div className="w-[90%] mx-auto lg:w-full">
              <img
                src={webbeeclientservices}
                className="w-full h-full object-contain"
                alt="Services Webbe Client Offers"
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full mt-8 lg:mt-0">
              <div className="">
                <h2 className="font-outfit font-extrabold justify-center text-3xl xl:text-4xl text-[#000000] flex">
                  Services
                  <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%] ml-2">
                    We Offer{" "}
                  </span>
                </h2>
              </div>
              <div className="mt-4">
                <p className="font-outfit text-[#000000] text-base xl:text-[15px] pt-4">
                  <b>Introducing </b>
                  <span className="text-[#F2B307] font-bold">WEBBE Client</span>
                  <br />
                  Your Ultimate Invoice and Payment Solution App! <br />
                  <br />
                  Seamlessly manage your business with{" "}
                  <span className="text-[#F2B307] font-bold">WEBBE Client</span>
                  , the all-in-one platform that simplifies your invoicing and
                  payment processes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-webbeeclient-features-bg bg-center bg-no-repeat bg-cover w-full py-10">
          <div className="w-[90%] lg:w-[80%] mx-auto">
            <h2 className="font-outfit font-extrabold justify-center text-3xl xl:text-4xl text-[#000000] flex">
              <span className="bg-rectangle-yellow bg-no-repeat bg-bottom bg-[length:100%_40%] mr-2">
                Powerful{" "}
              </span>
              Features
            </h2>
            <p className="text-[#2D2D2D] text-[18px] sm:text-[10px] md:text-[20px] lg:text-[22px] font-outfit font-semibold text-center py-2">
              Take your business to the next level with our powerful features:
            </p>
          </div>
          <div
            className="flex flex-row flex-wrap items-center justify-center pt-4 lg:pt-8 sm:px-16 md:px-20 xl:w-[85%] xl:mx-auto"
            data-aos="slide-up"
            data-aos-offset="100"
            data-aos-deleay="200"
            data-aos-once="true"
          >
            {Features.map(
              (item, index) => (
                <div
                  key={item.label}
                  className="flex flex-col items-center justify-center p-2 md:p-2 w-[50%] md:w-[33%] lg:w-[25%] relative group h-[220px] lg:h-[230px] xl:h-[245px] 2xl:h-[255px]"
                >
                  <img
                    src={item.img}
                    alt={item.label}
                    className="h-[60px] sm:h-[40px] md:h-[100px] lg:h-[75px] w-full object-contain"
                    // title={item.desc}
                  />
                  <div className="h-[70px] flex flex-col items-center">
                    <p className=" text-[#000000] text-[14px] md:text-[18px] lg:text-[22px] xl:text-[16px] pt-3 font-outfit font-bold w-full text-center ">
                      {item.label}
                    </p>
                    <p
                      className={`
                        text-[#000000]
                        text-[30px] sm:text-[20px] lg:text-[32px] xl:text-[28px] font-raleway font-extrabold w-full text-center mt-[-7px]`}
                    >
                      {item.number}
                    </p>
                  </div>
                  <div className="absolute bg-poly-black bg-contain bg-center bg-no-repeat opacity-0 group-hover:opacity-100 inset-0 h-full w-full flex justify-center items-center z-10">
                    <p className="text-white text-xs w-[80%] xl:w-[65%] 2xl:w-[45%] mx-auto text-center">
                      {item.desc}
                    </p>
                  </div>
                </div>
              )

              // <Featurecard key={index} item={item} />;
            )}
          </div>
          <div className="flex items-center justify-center mt-8">
            {/* {
              <Bookdemocard
                background={"bg-black text-white"}
                background2={"bg-[#FFFFFF]"}
              />
            } */}
            <Link to={"/contact"}>
              <div
                className="bg-[#FFBB00] text-white pb-[3px] cursor-pointer rounded-lg sm:rounded-md lg:rounded-lg w-max"
              >
                <div
                  className="bg-black rounded-lg sm:rounded-md lg:rounded-lg w-max py-[13px] font-outfit font-bold text-[16px] lg:text-[18px] xl:text-sm px-5 xl:px-6"
                >
                  Contact Us
                </div>
              </div>
            </Link>
            {/* <div
              className={`bg-[#FFBB00] text-white pb-[3px] cursor-pointer rounded-lg sm:rounded-md lg:rounded-lg w-max`}
            >
              <div className="bg-black rounded-lg sm:rounded-md lg:rounded-lg w-max py-[13px] font-outfit font-bold text-[16px] lg:text-[18px] xl:text-sm px-5 xl:px-6" >
                  <a
                  href="/contact"
                    className="" rel="noreferrer"
                  >
                   Contact Us
                  </a>
              </div>
            </div> */}
            {/* <button
              type="button"
              className="bg-[#F2B307] text-sm xl:mr-20 rounded-md px-6 py-3 flex items-center hover:bg-white hover:text-[#F2B307] text-white "
            >
              Book Your Demo Now
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebbeeClient;
