import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { AiFillCloseCircle } from "react-icons/ai";
import success from '../Resources/success.webp'
import { Route, Routes, Link } from "react-router-dom";

const baseURL = "https://api.webbeesite.com";

const SuccessPopup = (props) => {
	const [name, setname] = useState("");
	const [email, setemail] = useState();
	const [phonenumber, setphonenumber] = useState("");
	const [message, setmessage] = useState("");

	function Submit() {
	console.log("hello")
	}


	return (
		<section>
			{props.SuccessPopup && (
				<div className='bg-black/50 fixed inset-0 flex justify-center items-center z-50'>
					<div className=' bg-[#EFF1F3] py-8 w-[80%] rounded-lg px-5 sm:w-[50%] md:w-[50%] xl:w-[25%]  '>
				
						<div className="flex flex-col items-center">
					
                            <img src={success} className="h-[100px] sm:h-[80px] md:h-[100px] lg:h-[120px] w-full object-contain" />
                  <div className="py-3">
                            <p className=" text-[#2D2D2D] text-[14px] md:text-[18px] lg:text-[22px] xl:text-[16px] font-manrope font-bold w-full text-center ">Done!</p>
							<p className=" text-[#2D2D2D] text-[14px] md:text-[18px] lg:text-[22px] xl:text-[16px]  font-manrope font-medium w-full text-center ">{props.successmessage}</p>
                            </div>
								<div
									onClick={() => {
                                        props.onclose()
                                        window.location.href= props.websitelink;
									}}
									className='rounded-md bg-[#000000] mt-3 pb-[3px] w-full'
								>
									<p className="bg-[#F2B307] flex justify-center font-manrope cursor-pointer text-white w-full py-3 text-sm rounded-md">Go to Portal</p>
									
								</div>
                                <Link to={"/"}
								onClick={()=>{
                                    props.onclose()
                                }}
									className='bg-[#F2B307] mt-2 rounded-md w-full pb-[3px]'
								>
									<p className="bg-[#000000] flex justify-center font-manrope cursor-pointer text-white w-full py-3 text-sm rounded-md">Return Home</p>
								</Link>
						
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default SuccessPopup;
