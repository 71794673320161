import React, { useState, useEffect } from "react";
import Logo from "../Resources/Logo.webp";
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { motion } from "framer-motion";
import { Route, Routes, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import "./index.css";
import { setShowpopUp } from "../Actions/actions";

const Scrolltoform = ({ background, background2, click, title }) => {

	const dispatch = useDispatch();

	return (
		// <div
		// 	onClick={() => {
		// 		dispatch(setShowpopUp(true));
		// 	}}
		// 	className={`${background} cursor-pointer p-3 sm:p-2 md:p-3 rounded-lg sm:rounded-md lg:rounded-lg w-max my-2 md:my-4`}
		// >
		// 	<p className={`${background2} text-[16px] lg:text-[18px] xl:text-sm px-5 xl:px-3`}>
		// 		Book Your Demo Now
		// 	</p>
		// </div>
	
		<div
			onClick={click}
			className={`${background2} pb-[3px] cursor-pointer rounded-lg sm:rounded-md lg:rounded-lg w-max`}
		>
			<div className={`${background} rounded-lg sm:rounded-md lg:rounded-lg w-max py-[13px] font-outfit font-bold text-[16px] lg:text-[18px] xl:text-sm px-5 xl:px-6`}>
				{title}
			</div>
		</div>

	);
};

export default Scrolltoform;
