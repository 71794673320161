import React from "react";
import Seperator from "../Seperator";

import ServiceRecommendationImg from "../../Resources/serviceRecommendationImg.webp"

const ServicesRecommending = () => {
	return (
		<div className="bg-[#FEF2E1] py-10">
			<section className="w-[90%] mx-auto xl:w-[80%] sm:flex sm:flex-row-reverse sm:justify-center sm:items-center">
				<div className="xl:pl-24 flex flex-col items-center xl:items-start xl:w-[55%]">
					<h1 className='text-[#2D2D2D] font-outfit font-extrabold text-center xl:text-left text-3xl xl:text-4xl mt-3'>
						<span className="bg-rectangle-yellow bg-no-repeat bg-[center_bottom_0.3rem] bg-[length:100%_35%]">Services</span> that are worth recommending!
					</h1>
					<Seperator />
					<p className="text-base xl:text-[15px] text-[#2D2D2D] text-justify font-outfit font-medium pt-5 xl:pt-0">
						Our outstanding website development company in UAE is worth recommending!
						With <span className="text-[#F2B307]">WEBBEESITE</span> , it is easy to provide your customers with what they
						need.
					</p>
					<p className="text-base xl:text-[15px] text-[#2D2D2D] text-justify font-outfit font-medium py-4">
						You can earn up to AED 1000 for every new customer you refer to us. Just refer your customers and social media followers to get the website.
					</p>
					<p className="text-base xl:text-[15px] text-[#2D2D2D] text-justify font-outfit font-medium">
						Once you're registered, you can start earning money right away. You'll receive a commission for every customer who renews their plan with <span className="text-[#F2B307]">WEBBEESITE</span>. The more customers you refer, the more money you'll make.
					</p>
				</div>
				<div className="xl:w-[45%] xl:flex xl:justify-center">
					<img src={ServiceRecommendationImg} alt="" className="w-[480px]"/>
				</div>
			</section>
		</div>
	);
};

export default ServicesRecommending;
